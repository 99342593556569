import { DanhMucKhenThuongDTO } from "../../apis/danhMuc/danhMucKhenThuong";
import { DanhMucDanhHieuAction, DanhMucHinhThucAction } from "../actions/danhMucKhenThuongAction";

//** Danh mục hình thức khen thưởng */
export interface DanhMucHinhThucState {
    lstDanhMucHinhThuc: DanhMucKhenThuongDTO[];
}

const initialStateHinhThuc = {
    lstDanhMucHinhThuc: []
}

export const dmHinhThucReducer = (state: DanhMucHinhThucState = initialStateHinhThuc, action: DanhMucHinhThucAction) => {
    switch (action.type) {
        case "GET":
            return state;
        case "PUSH":
            return state = {
                lstDanhMucHinhThuc: action.dmHinhThuc
            }
        default: return state;
    }
}

//** Danh mục danh hiệu khen thưởng */
export interface DanhMucDanhHieuState {
    lstDanhMucDanhHieu: DanhMucKhenThuongDTO[];
}

const initialStateDanhHieu = {
    lstDanhMucDanhHieu: []
}

export const dmDanhHieuReducer = (state: DanhMucDanhHieuState = initialStateDanhHieu, action: DanhMucDanhHieuAction) => {
    switch (action.type) {
        case "GET":
            return state;
        case "PUSH":
            return state = {
                lstDanhMucDanhHieu: action.dmDanhHieu
            }
        default: return state;
    }
}
