import { combineReducers } from "redux";
import { applicationReducer } from "./applicationReducer";
import { authenticateReducer } from "./authenticateReducer";
import { dmHinhThucReducer, dmDanhHieuReducer } from "./danhMucKhenThuongReducer";
import { menuReducer } from "./menuReducer";
export const rootReducer = combineReducers({
    authenticate: authenticateReducer,
    application: applicationReducer,
    dmHinhThuc: dmHinhThucReducer,
    dmDanhHieu: dmDanhHieuReducer,
    menuReducer: menuReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
