import { Backdrop, CircularProgress, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useAxiosLoader } from "../../hooks/useAxiosLoader";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: 9999,
            color: '#fff',
        },
    }),
);
export const OverlayLoading = () => {
    const classes = useStyles();
    const [loading] = useAxiosLoader();

    return (
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}