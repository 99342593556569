import { PasswordHistoryInterface } from "../../model/passwordHistory";
import { User } from "../../model/user";
import { BaseResponse, BaseService } from "../baseService";
import axios from "../index";
export interface UserData {
    sessionValue: string;
    userId: string;
    superAdmin: boolean;
    userName: string;
    fullName: string;
};
export class UserManagementService extends BaseService {
    getSession = () => {
        return axios.post('/user-mgt/get-session');
    }
    getUser = async (query: string, page: number, size: number): Promise<{ rows: User[], total: number }> => {
        var list: User[] = [];
        var total: number = 0;
        await axios.get('/user-mgt/users?skip=' + page + '&limit=' + size + '&query=' + query, this.getTokenRequestHeaders())
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject(new Error("Invalid status code received in the token response: "
                        + response.status));
                }
                if (response.data.error_code === 'SUCCESSFUL') {
                    total = response.data.data.sum;
                    list = response.data.data.rs;
                }
            }).catch((error) => {
                return Promise.reject(error);
            });
        return { rows: list, total: total };
    }

    getUserRoles = async (fullName: string): Promise<any> => {
        let resp = {};
        await axios.post('/decentralization/user', { fullName: fullName }, this.getTokenRequestHeaders())
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject(new Error("Invalid status code received in the token response: "
                        + response.status));
                }
                resp = response.data.data;
            }).catch((error) => {
                return Promise.reject(error);
            });
        return resp;
    }

    saveUserRole = async (request: any): Promise<BaseResponse> => {
        let resp = { isSuccess: false } as BaseResponse;
        await axios.post('/decentralization/save', JSON.stringify(request), this.getTokenRequestHeaders())
            .then(response => {
                if (response.status !== 200) {
                    resp.message = 'Có lỗi xảy ra, thực hiện lại!';
                } else {
                    resp.isSuccess = response.data.error_code === 'SUCCESSFUL';
                    if (resp.isSuccess) {
                        resp.message = 'Cập nhật vai trò người dùng thành công';
                    } else {
                        resp.message = 'Cập nhật vai trò người dùng thất bại';
                    }
                }
            }).catch((error) => {
                resp.message = 'Không thể kết nối tới server!';
            });
        return resp;
    }

    getRoleByUserId = async (userId: string): Promise<any> => {
        let resp = {};
        await axios.get('/decentralization/grouprole-role/' + userId, this.getTokenRequestHeaders())
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject(new Error("Invalid status code received in the token response: "
                        + response.status));
                }
                resp = response.data.data;
            }).catch((error) => {

            });
        return resp;
    }

    insertOrUpdateUser = async (user: User): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios.post('/user-mgt/user/insertOrUpdate', JSON.stringify(user), this.getTokenRequestHeaders())
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject(new Error("Invalid status code received in the token response: "
                        + response.status));
                }
                result = {
                    isSuccess: response.data.error_code === 'SUCCESSFUL',
                    message: response.data.error_message,
                    data: response.data.data
                };
            }).catch((error) => {
                result = { isSuccess: false, message: 'Có lỗi xảy ra, thực hiện lại!' };
            });
        return result;
    }

    deleteUser = async (userId: string): Promise<any> => {
        return axios.post('/user-mgt/user/delete', JSON.stringify({ userId: userId }), this.getTokenRequestHeaders())
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject('Có lỗi xảy ra, thực hiện lại!');
                }
                return response.data;
            }).catch((error) => {
                return Promise.reject('Lỗi khi kết nối server!');
            });
    }

    unlockedUser = async (userId: string): Promise<any> => {
        return axios.post('/user-mgt/unlockedUser', JSON.stringify({ userId: userId }), this.getTokenRequestHeaders())
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject(new Error("Invalid status code received in the token response: "
                        + response.status));
                }
                return response.data;
            }).catch((error) => {
                return Promise.reject(error);
            });
    }

    doAuthenticate = async (credential: { userName: string, password: string }): Promise<any> => {
        let result = {};
        await axios.post('/user-mgt/authenticate',
            JSON.stringify(credential), this.getTokenRequestHeaders())
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject(new Error("Invalid status code received in the token response: "
                        + response.status));
                }
                result = {
                    isSuccess: response.data.error_code === 'SUCCESSFUL',
                    message: response.data.error_message,
                    data: response.data.data
                };
            }).catch((error) => {
                result = { isSuccess: false, message: 'Đăng nhập thất bại, thực hiện lại!' };
            });
        return result;
    }

    validateSession = async (ssid: string): Promise<boolean> => {
        let result = false;
        await axios.post('/user-mgt/validateSession',
            JSON.stringify({ sessionValue: ssid }), this.getTokenRequestHeaders())
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject(new Error("Invalid status code received in the token response: "
                        + response.status));
                }
                if (response.data.error_code === 'SUCCESSFUL') {
                    result = response.data.data;
                }
            }).catch((error) => {
                return Promise.reject(error);
            });
        return result;
    }

    terminateSession = async (ssid: string): Promise<boolean> => {
        let result = false;
        await axios.post('/user-mgt/terminateSession',
            JSON.stringify({ sessionValue: ssid }), this.getTokenRequestHeaders())
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject(new Error("Invalid status code received in the token response: "
                        + response.status));
                }
                if (response.data.error_code === 'SUCCESSFUL') {
                    result = response.data.data;
                }
            }).catch((error) => {
                return Promise.reject(error);
            });
        return result;
    }

    getUserAdminInfo = async (userId: string): Promise<any> => {
        return axios.post("/user-mgt/userInfo", JSON.stringify({ userId: userId }), this.getTokenRequestHeaders())
            .then((response: any) => {
                if (response.status !== 200) {
                    return Promise.reject(new Error("Invalid status code received in the token response: "
                        + response.status));
                }
                return response.data.data;

            }).catch((error: any) => {
                return Promise.reject(error);
            });
    };

    changePassword = async (bodyRequest: { userId: string, oldPassword: string, newPassword: string }): Promise<any> => {
        return axios.post("/user-mgt/change-password", JSON.stringify(bodyRequest), this.getTokenRequestHeaders())
            .then((response: any) => {
                if (response.status !== 200) {
                    return Promise.reject(new Error("Invalid status code received in the token response: "
                        + response.status));
                }
                return response.data;
            }).catch((error: any) => {
                return Promise.reject(error);
            });
    };

    passwordHistory = async (userId: string, page: number, size: number): Promise<{ rows: PasswordHistoryInterface[], total: number }> => {
        var list: PasswordHistoryInterface[] = [];
        var total: number = 0;
        await axios.get('/user-mgt/password-history?skip=' + page + '&limit=' + size + '&userId=' + userId, this.getTokenRequestHeaders())
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject(new Error("Invalid status code received in the token response: "
                        + response.status));
                }
                if (response.data.error_code === 'SUCCESSFUL') {
                    total = response.data.data.sum;
                    response.data.data.rs.forEach((item: any) => {
                        item.showPass = false;
                        list.push(item);
                    })
                }
            }).catch((error) => {
                return Promise.reject(error);
            });
        return { rows: list, total: total };
    }

    deletePasswordHistory = async (id: number): Promise<any> => {
        return await axios.post('/user-mgt/password-history/delete', JSON.stringify({ passwordHistoryId: id }), this.getTokenRequestHeaders())
            .then(response => {
                if (response.status !== 200) {
                    return Promise.reject(new Error("Invalid status code received in the token response: "
                        + response.status));
                }
                return response.data;
            }).catch((error) => {
                return Promise.reject(error);
            });
    }

    getUserInfoByAccessToken = async (accessToken: string): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios.get("/user-mgt/sso/userInfo", {
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + accessToken
            }
        }).then((response: any) => {
            result = {
                isSuccess: true,
                message: '',
                data: response.data.data
            } as BaseResponse;
        }).catch(() => {
            result = {
                isSuccess: true,
                message: 'Lỗi khi thực hiện lấy thông tin người dùng',
            } as BaseResponse;
        });
        return result;
    };

    exportUser = async (query: string, downloadType: string): Promise<any> => {
        let params = { query: query, downloadType: downloadType };
        return await axios.get("/user-mgt/users/export", {
            params: params,
            responseType: "blob",
            data: {},
            timeout: 30000,
        });
    }
}
