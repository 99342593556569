export default class StringUtil {
    static isNullOrEmty(str: any): boolean {
        if (typeof str == "undefined" || !str || str.length === 0 || str === "" || !/[^\s]/.test(str) || /^\s*$/.test(str) || str.toString().replace(/\s/g, "") === "") return true;
        else return false;
    }
    static formatMoney(str: string): string {
        if (str.match(/,/g)) {
            const newString = `${str.toString().replace(/,/g, "")}`;
            return `${newString.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
        return `${str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
    static formatForDot(str: any): string {
        if (!StringUtil.isNullOrEmty(str)) {
            str = str.toString().replace(/\D/g, "");
            return `${str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
        }
        return "";
    }
    static checkSingleYear(str: string) {
        if (str.match(/^\d{1,4}$/g)) {
            return str;
        }
    }
    static checkFromToYear(str: string) {
        if (str.match(/^\d{1,4}-$/g)) {
            return str;
        }

        if (str.match(/^\d{0,4}-\d{0,4}$/g)) {
            return str;
        }

        if (str.match(/^\d{1,4}$/g)) {
            if (str.match(/^\d{0,4}-{0,1}\d{0,4}$/g)) {
                return str;
            }
            return str;
        }
    }
}
