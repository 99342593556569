import { Button, Grid, InputBase, InputLabel, NativeSelect, Snackbar } from "@material-ui/core";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BaseResponse } from "../../../apis/baseService";
import { AlertMessage, CustomAlert } from "../../../components/commons/alert";
import { ConfirmationDialogRaw } from "../../../components/commons/dialog/confirmDialog";
import { LoadingAction, showLoading, hideLoading } from "../../../redux/actions/applicationAction";

import { bootstrapTextFieldHook } from "../../../components/commons/input/bootstrap";
import { DataListDMLinhVuc } from "./dataListDMLinhVuc";
import { DanhMucLinhVucDTO, DanhMucLinhVucRequest, DanhMucLinhVucService } from "../../../apis/danhMuc/danhMucLinhVuc";
import { DMLinhVucEditDialog } from "./dmLinhVucEditDialog";

export const DanhMucLinhVucMgt = () => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dataList, setDataList] = useState<DanhMucLinhVucDTO[]>([]);
    const [itemSeleted, setItemSeleted] = useState<DanhMucLinhVucDTO>({} as DanhMucLinhVucDTO);

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(5);
    const [total, setTotal] = useState(0);

    const [keyword, setKeyword] = useState("");
    const [status, setStatus] = useState(0);

    const dispatch = useDispatch<Dispatch<LoadingAction>>();

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };

    const loadDataTable = async () => {
        dispatch(showLoading());
        let isActive;
        if (status > 0) {
            isActive = status === 1;
        }
        let request: DanhMucLinhVucRequest = { keywords: keyword, isActive: isActive, pageNumber: page, pageSize: size };
        await new DanhMucLinhVucService()
            .getDMLinhVuc(request)
            .then((p) => {
                setDataList(p.rows ? p.rows : []);
                setTotal(p.total);
            })
            .finally(() => {
                dispatch(hideLoading());
            });
    };
    useEffect(() => {
        loadDataTable();
    }, [page, size]); // eslint-disable-line react-hooks/exhaustive-deps

    const doSearch = () => {
        loadDataTable();
    };
    const handleSubmitData = (resp: BaseResponse) => {
        if (resp.isSuccess) {
            setOpenDialog(false);
            setPage(0);
            loadDataTable();
            showMessageAlert(resp.message);
        } else {
            showMessageAlert(resp.message, false);
        }
    };
    const showEditDialog = (item: DanhMucLinhVucDTO) => {
        dispatch(showLoading());
        setItemSeleted(item);
        setOpenDialog(true);
        dispatch(hideLoading());
    };

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSize(Number(event.target.value));
    };

    const handleDeleteItem = (id: string) => {
        if (id) {
            setItemSeleted({ id: id } as DanhMucLinhVucDTO);
            setOpenDeleteUserDlg(true);
        }
    };

    const handleCloseDeleteRole = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            if (itemSeleted.id) {
                dispatch(showLoading());
                await new DanhMucLinhVucService()
                    .deleteById(itemSeleted.id)
                    .then((resp: BaseResponse) => {
                        if (resp.isSuccess) {
                            loadDataTable();
                            showMessageAlert(resp.message);
                        } else {
                            showMessageAlert(resp.message, false);
                        }
                    })
                    .catch((e: any) => {
                        showMessageAlert(e, false);
                    })
                    .finally(() => {
                        dispatch(hideLoading());
                    });
            }
        }
        setOpenDeleteUserDlg(false);
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>Danh mục lĩnh vực</span>
            </div>
            <form noValidate autoComplete="off">
                <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item xs={6}>
                        <InputLabel classes={labelStyles}>Từ khóa tìm kiếm</InputLabel>
                        <InputBase
                            fullWidth
                            placeholder="Nhập nội dung tìm kiếm"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            classes={bootstrapTextFieldHook.useInput()}
                            inputProps={{ "aria-label": "description" }}
                        />
                    </Grid>
                    <Grid item xs={6} className="grid-item">
                        <InputLabel classes={labelStyles}>Hoạt động</InputLabel>
                        <NativeSelect input={<InputBase fullWidth classes={inputStyles}/>} value={status} onChange={(e) => setStatus(Number(e.target.value))}>
                            <option value={0}>Chọn</option>
                            <option value={1}>Hoạt động</option>
                            <option value={2}>Ngừng hoạt động</option>
                        </NativeSelect>
                    </Grid>

                    <Grid item xs={12} className="button-group-center">
                        <Button variant="contained" color="primary" disableElevation onClick={doSearch}>
                            Tìm kiếm
                        </Button>
                        <Button variant="contained" disableElevation onClick={() => showEditDialog({ isActive: false } as DanhMucLinhVucDTO)}>
                            Thêm mới
                        </Button>
                    </Grid>
                </Grid>
                <div>
                    <DataListDMLinhVuc
                        dataList={dataList}
                        deleteItem={handleDeleteItem}
                        pageProps={{
                            page: page,
                            count: total,
                            rowsPerPage: size,
                            onChangePage: handleOnChangePage,
                            onChangeRowsPerPage: handOnChangeRowsPerPage,
                        }}
                        modalEdit={{ open: true, openEditDialog: showEditDialog }}
                    />
                    {openDialog && <DMLinhVucEditDialog openDialog={openDialog} onDialogClose={() => setOpenDialog(false)} itemEdit={itemSeleted} onSubmitData={handleSubmitData} />}
                </div>
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-user"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa danh mục"
                    content="Bạn có đồng ý thực hiện hành động này?"
                    onClose={handleCloseDeleteRole}
                />
                <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                    <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                        {alertMessage.content}
                    </CustomAlert>
                </Snackbar>
            </form>
        </div>
    );
};
