import axios from "../index";
import { BaseResponse, BaseService } from "../baseService";
import { UserUpdate } from "../../model/user";
const ROOT_PATH = "/user-mgt";
export class ProfileUpdateService extends BaseService {
    async update(data: UserUpdate): Promise<BaseResponse> {
        let result = { isSuccess: false, } as BaseResponse;
        await axios.post(`${ROOT_PATH}/admin/update-user-profile`, data, this.getTokenRequestHeaders()).then((response) => {
            result = {
                isSuccess: response.data.error_code === "SUCCESSFUL",
                message: response.data.error_message,
                data: response.data.data,
            };
        }).catch(() =>
            result = { ...result, message: 'Có lỗi khi kết nối tới server' }
        );
        return result;
    }

    async getUserInfo(userId: string): Promise<UserUpdate> {
        let result = {} as UserUpdate;
        await axios.post(`/user-mgt/admin/user-profile`, { userId: userId }, this.getTokenRequestHeaders()).then((response) => {
            result = response.data.data;
        }).catch();
        return result;
    }
}
