import { ImportDauKyCuaRequest } from "../../pages/importDauKy/model/ImportDauKyModel";
import { BaseService } from "../baseService";
import axios from "../index";
export class ImportDauKyService extends BaseService {

    downloadFileTemplate = async (requestData: ImportDauKyCuaRequest): Promise<any> => {
        return axios.post("giay-phep-dau-kys/download-template", requestData, {
            responseType: "blob",
        });
    };
    importData = async (requestData: ImportDauKyCuaRequest, data: File): Promise<any> => {
        let body = new FormData();
        body.append("file", data);
        return axios.post("giay-phep-dau-kys/import/" + requestData.loaiGiayPhep, body, {
            headers: {
                "Content-Type": "multipart/form-data;charset=UTF-8",
            },
        });
    };
}
