import AXIOS_INSTANCE from "..";
import { DongBoDanhMucMotCuaRequest } from "./model/MotCuaModel";

export default class MotCuaService {
    constructor() { }
    dongBoMotCua = (request: DongBoDanhMucMotCuaRequest) => {
        return AXIOS_INSTANCE.post('mot-cua/dong-bo-danh-muc', request,{timeout : 600000});
    }
    dongBoCanBoCongChuc = (maCoQuan: string) => {
        return AXIOS_INSTANCE.post('mot-cua/dong-bo-can-bo-cong-chuc',  {maCoQuan},{timeout : 600000});
    }
}
