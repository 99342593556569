import { Button, Grid, InputBase, InputLabel, NativeSelect, Snackbar } from '@material-ui/core';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CoQuanDTO, DanhMucService } from '../../../apis/danhMuc/danhMucService';
import MotCuaService from '../../../apis/quanTri/MotCuaService';
import { AlertMessage, CustomAlert } from '../../../components/commons/alert';
import { ConfirmationDialogRaw } from '../../../components/commons/dialog/confirmDialog';
import { bootstrapTextFieldHook } from '../../../components/commons/input/bootstrap';
import { OverlayLoading } from '../../../components/overlayLoading/overlayLoading';
import { hideLoading, LoadingAction, showLoading } from '../../../redux/actions/applicationAction';
import StringUtil from '../../../utils/stringUtils';

export const DongBoCanBoCongChucComponent = () => {
    const [openConfirm, setOpenConfirm] = useState(false);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);
    const dispatch = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<CoQuanDTO[]>([]);
    const [coQuan, setCoQuan] = useState<string>('');
    const inputStyles = bootstrapTextFieldHook.useInput();
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }
    const service = new MotCuaService();
    useEffect(() => {
        async function getDuLieu() {
            const result = await new DanhMucService().getDanhMucCoQuanCode_HoatDong(true);
            if(result && result.length > 0) {
                setCoQuan(result[0]?.ma);
            }
            setData(result)
        }
        getDuLieu();
    }, []);

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }))
    }

    const dongBo = () => {
        setOpenConfirm(true);
    }
    const handleDongBo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            dispatch(showLoading());
            if(!StringUtil.isNullOrEmty(coQuan)){
                service.dongBoCanBoCongChuc(coQuan).then((p) => {
                    if (p.data && p.data.error_code === 'SUCCESSFUL' ) {
                        showMessageAlert('Đồng bộ thành công', true);
                    } else {
                        showMessageAlert('Đồng bộ thất bại', false);
                    }
                }).catch(e => {
                    showMessageAlert(e, false);
                }).finally(() => {
                    dispatch(hideLoading());
                });

            }
        }
        setOpenConfirm(false);
    };


    const handleChange = (event: any) => {
        setCoQuan(event.target.value);
      };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>Đồng bộ người dùng</span>
            </div>
            <Grid container spacing={2} direction="row" alignItems="center">
                <OverlayLoading />
                <Grid item xs={6} className="gridItem">
                    <InputLabel classes={labelStyles}>Cơ quan</InputLabel>
                    <NativeSelect input={<InputBase fullWidth classes={inputStyles} />} value={coQuan} onChange={handleChange}>
                        {
                            data?.map((row, i) => (
                                <option value={row?.ma}>{row?.ten}</option>   
                            ))
                        }    
                    </NativeSelect>
                </Grid>
                <Grid item xs={12} className="button-group-center">
                    <Button variant="contained" color="primary" disableElevation
                        onClick={dongBo}>
                        Đồng bộ
                    </Button>
                </Grid>
            </Grid>
            <ConfirmationDialogRaw id="confirm-dialog-dong-bo" keepMounted open={openConfirm}
                title="Đồng bộ"
                content="Bạn có đồng ý thực hiện thao tác này?" onClose={handleDongBo}
            />
            <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
            </Snackbar>
        </div>
    )
}