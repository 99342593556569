import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import { ThongKeKhenThuongTheoDonViDTO } from '../../apis/chuyenNganh/baoCaoThongKeKhenThuongService';
import { Cell, Row } from '../../components/commons/table/tableCommons';

interface IDataListProps {
    data: ThongKeKhenThuongTheoDonViDTO[];
    modeTK: string;
}

export const DanhSachThongKeTinhHinhCapPhep = (props: IDataListProps) => {
    const [rows, setRows] = useState<ThongKeKhenThuongTheoDonViDTO[]>([]);
    const [modeTK, setModeTK] = useState<string>("0");
    const [tenLoaiThongKe, setTenLoaiThongKe] = useState<string>("Loại đối tượng");
    useEffect(() => {
        setRows(props.data);
        setModeTK(props.modeTK);
        if (props.modeTK == '0') {
            setTenLoaiThongKe("Loại đối tượng");
        } else if (props.modeTK == '1') {
            setTenLoaiThongKe("Loại hình thức khen thưởng");
        } else if (props.modeTK == '2') {
            setTenLoaiThongKe("Loại danh hiệu khen thưởng");
        }
    }, [props.data, props.modeTK]);

    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                        <Cell component="th" scope="row" align="center" >Đơn vị cấp phép</Cell>
                        <Cell component="th" scope="row" align="center" >{tenLoaiThongKe}</Cell>
                        <Cell component="th" scope="row" align="center" >Số lượng được khen thưởng</Cell>
                        <Cell component="th" scope="row" align="center" >Số lượng bị hủy khen thưởng</Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows?.length > 0 ?
                            rows.map((row, i) =>
                            (
                                <>
                                    <Row key={i}>
                                        <Cell scope="row" align="center">
                                            {i + 1}
                                        </Cell>
                                        <Cell align="left">{row.tenDonVi}</Cell>
                                        <Cell align="left" />
                                        <Cell align="left">{row.totalKT}</Cell>
                                        <Cell align="left">{row.totalHuyKT}</Cell>
                                    </Row>
                                    {
                                        row.chiTiets?.length > 0 &&
                                        row.chiTiets?.map((chiTiet, i) => (
                                            <Row key={i}>
                                                <Cell scope="row" align="center" />
                                                <Cell align="left" />
                                                <Cell align="left" >{chiTiet.label}</Cell>
                                                <Cell align="left">{chiTiet.totalKT}</Cell>
                                                <Cell align="left">{chiTiet.totalHuyKT}</Cell>
                                            </Row>
                                        ))
                                    }
                                </>
                            )
                            ) :
                            <Row>
                                <Cell colSpan={5} scope="row" align="center"> Không có dữ liệu</Cell>
                            </Row>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
