import { IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";

export interface EndAdornmentSearchProps {
    onClick?: () => void;
}

const useStyles = makeStyles({
    root: {
        position: "absolute",
        right: "10px",
    },
});

const EndAdornmentSearch: React.FC<EndAdornmentSearchProps> = ({ onClick }) => {
    const classes = useStyles();

    return (
        <InputAdornment position="end" classes={classes}>
            <IconButton edge="end" disableRipple disableTouchRipple disableFocusRipple onClick={onClick}>
                <SearchIcon fontSize="small" color="primary" />
            </IconButton>
        </InputAdornment>
    );
};

export default EndAdornmentSearch;
