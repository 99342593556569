import { Box, Button, Grid, TextField, TextFieldProps } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { createMuiTheme, createStyles, makeStyles, Theme, ThemeProvider } from "@material-ui/core/styles";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { MenuRoleItem, MenuRoleManagementService } from "../apis/quanTri/menuRoleManagementService";
import { UserManagementService } from "../apis/userMgt/userManagementService";
import { Footer } from "../components/footer/footer";
import { HeaderBar } from "../components/headerBar/headerBar";
import { OverlayLoading } from "../components/overlayLoading/overlayLoading";
import { SubMenuTree } from "../components/siderBar";
import { RoutePropsI, ROUTE_PAGE } from "../routes";
import { useDispatch } from "react-redux";
import { addMenuRole, restMenu } from "../redux/actions/menuAction";
import RefreshIcon from "@material-ui/icons/Refresh";
import EndAdornmentSearch from "../components/commons/endAdornmentSearch";
import ChildrenLayout from "./layoutChildren";
import { getObject, getString } from "../helpers/localStorage";
import StringUtil from "../utils/stringUtils";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#367fa9",
            contrastText: "#fff",
        },
        secondary: {
            main: red[500],
        },
        warning: {
            main: "#f59a23",
            contrastText: "#fff",
        },
    },
    typography: {
        button: {
            textTransform: "none",
        },
    },
});

const inputStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            input: {
                padding: 10,
                color: "white",
                fontSize: 17,
            },
        }),
    { index: 1 }
);

export const RouteWithSubRoutes = (route: RoutePropsI) => {
    return <Route exact path={route.duongDan} render={(props) => <route.giaoDien {...props} routes={route.routes} />} />;
};

type UserData = {
    sessionValue: string;
    userId: string;
    superAdmin: boolean;
    userName: string;
};

export function Layout(props: { children: JSX.Element }): JSX.Element {
    const classes = inputStyles();
    const [cRouter, setCRouter] = useState<RoutePropsI[]>([]);
    const [curentUserRouter, setCurrentUserRoute] = useState<MenuRoleItem[]>([]);
    const [cloneUserRouter, setCloneUserRouter] = useState<MenuRoleItem[]>([]);
    const [superAdmin, setSuperAdmin] = useState<boolean>(false);
    const dispatch = useDispatch();
    useEffect(() => {
        let C_USER_DATA_DE = getObject("C_USER_DATA") as UserData;
        if (!StringUtil.isNullOrEmty(C_USER_DATA_DE.userId) && !StringUtil.isNullOrEmty(C_USER_DATA_DE.userName)) {
            setSuperAdmin(C_USER_DATA_DE.superAdmin);
            dispatch(addMenuRole({ isSupperAdmin: C_USER_DATA_DE.superAdmin }));
            loadMenuForCurrentUser(C_USER_DATA_DE.userName);
        } else {
            getUserInfoByAccessToken();
        }
    }, []);
    async function loadMenuForCurrentUser(userName: string) {
        let menus: MenuRoleItem[] = [];
        await new MenuRoleManagementService().getMenuByUserName(userName).then((response) => {
            menus.push(...response);
            //lấy ra những component trong routes_user có path trùng với menu để render ra.
            let arrayNode: MenuRoleItem[] = [];
            response.forEach((item) => {
                arrayNode.push(...convertTreeToList(item));
            });
            let routeForUser: RoutePropsI[] = [];
            arrayNode.forEach((item) => {
                let route = ROUTE_PAGE.filter((p) => p.duongDan === item.path_)[0];
                if (route) {
                    routeForUser.push(route);
                }
            });
            setCRouter(routeForUser);
        });
        setCurrentUserRoute(menus);
        setCloneUserRouter(menus);
    }
    const getUserInfoByAccessToken = useCallback(async () => {
        let accessToken = getString("ACCESS_TOKEN");
        if (accessToken) {
            await new UserManagementService()
                .getUserInfoByAccessToken(accessToken)
                .then((p) => {
                    if (p.isSuccess) {
                        loadMenuForCurrentUser(p.data.sub);
                        localStorage.setItem("C_USER_DATA", JSON.stringify(p.data));
                    }
                }).catch((e) => console.log(e));
        }
    }, []);
    function convertTreeToList(root: MenuRoleItem): MenuRoleItem[] {
        var stack: MenuRoleItem[] = [],
            array: MenuRoleItem[] = [],
            hashMap = {};
        stack.push(root);
        while (stack.length !== 0) {
            var node = stack.pop();
            if (node) {
                if (node.childrens && node.childrens.length > 0) {
                    for (var i = node.childrens.length - 1; i >= 0; i--) {
                        stack.push(node.childrens[i]);
                    }
                } else {
                    visitNode(node, hashMap, array);
                }
            }
        }

        return array;
    }

    function visitNode(node: MenuRoleItem, hashMap: any, array: MenuRoleItem[]) {
        if (!hashMap[node.id]) {
            hashMap[node.id] = true;
            array.push(node);
        }
    }

    const typingRef = useRef<any>(null);
    const [inputValue, setInputValue] = useState<string>("");

    function handleSearch(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const value = e.target.value;
        setInputValue(e.target.value);
        if (typingRef.current) {
            clearTimeout(typingRef.current);
        }
        // if (typingRef) {
        typingRef.current = setTimeout(() => {
            if (value) {
                const newArr = handleReduceArr(cloneUserRouter);
                const newParttern = new RegExp(value.toLocaleLowerCase(), "i");
                setCurrentUserRoute((prev) => [...newArr.filter((item: any) => newParttern.test(item.name.toLowerCase()))]);
            }
        }, 500);
        // }
        // const value = typingRef && typingRef.current && (typingRef.current.value as String);
    }

    function handleReduceArr(arr: MenuRoleItem[]): MenuRoleItem[] {
        const newArr = arr.reduce((acc: MenuRoleItem[], currentValue: MenuRoleItem) => {
            if (currentValue.childrens && currentValue.childrens?.length > 0) {
                return [...acc, ...currentValue.childrens];
            }
            if (currentValue.childrens && currentValue.childrens.length === 0) {
                return [...acc, currentValue];
            }
            return acc;
        }, []);

        if (newArr.some((item: MenuRoleItem) => item.childrens && item.childrens.length > 0)) {
            return handleReduceArr(newArr);
        }

        return newArr;
    }

    function handleReset() {
        setCurrentUserRoute(cloneUserRouter);
        setInputValue("");
        dispatch(restMenu());
    }
    return (
        <ThemeProvider theme={theme}>
            <HeaderBar />
            <Box display="flex" flexGrow={1} padding={0} bgcolor="#efeff6">
                <Grid container style={{ overflowX: "hidden", width: "100%", height: "fit-content", minHeight: "84vh" }}>
                    <Grid item xs={2} style={{ backgroundColor: "#555555" }}>
                        <TextField
                            // inputRef={typingRef}
                            fullWidth
                            value={inputValue}
                            onChange={(event) => handleSearch(event)}
                            placeholder="Tìm kiếm menu"
                            type="text"
                            InputProps={{
                                disableUnderline: true,
                                classes: classes,
                                endAdornment: <EndAdornmentSearch />,
                            }}
                        // onKeyPress={(e) => {
                        //     if (e.key === "Enter") {
                        //         handleSearch();
                        //     }
                        // }}
                        />
                        <Box style={{ paddingTop: 5, paddingLeft: 10, paddingRight: 10 }}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                startIcon={<RefreshIcon fontSize="small" style={{ color: "white" }} />}
                                onClick={handleReset}
                            >
                                Làm mới
                            </Button>
                        </Box>
                        <Switch>
                            <Route render={(props) => curentUserRouter.map((menu, index) => <SubMenuTree key={index} menu={menu} />)} />
                        </Switch>
                    </Grid>
                    <ChildrenLayout cRoute={cRouter} />
                    {props.children}
                </Grid>
                <OverlayLoading />
            </Box>
            <Footer />
        </ThemeProvider>
    );
}
