import { Box, IconButton, NativeSelect } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { DanhMucPhuongThucDTO } from '../../../apis/danhMuc/danhMucPhuongThuc';
import { CustomInput } from '../../../components/commons/input/customInput';
import { Cell, Row, CheckIconDeActive } from '../../../components/commons/table/tableCommons';


interface IModalProps {
    openEditDialog: (itemSelected: DanhMucPhuongThucDTO) => void;
    open: boolean,
}
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}
interface IDataListProps {
    dataList: DanhMucPhuongThucDTO[];
    deleteItem: (id: string) => void;
    modalEdit: IModalProps;
    pageProps: TablePaginationActionsProps;
}

export const DataListDMPhuongThuc = (props: IDataListProps) => {

    const [rows, setRows] = useState<DanhMucPhuongThucDTO[]>([]);
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);

    useEffect(() => {
        setRows(props.dataList);
        setTotalPage(Math.ceil(props.pageProps.count / props.pageProps.rowsPerPage));
        setRowsPerPage(props.pageProps.rowsPerPage);
    }, [props.dataList, props.pageProps.count, props.pageProps.rowsPerPage]);

    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">STT</Cell>
                            <Cell component="th" scope="row" align="center" width="10%">Mã</Cell>
                            <Cell component="th" scope="row" align="center" width="45%">Tên phương thức</Cell>
                            <Cell component="th" scope="row" align="center" width="10%">Hoạt động</Cell>
                            <Cell component="th" scope="row" align="center" width="10%">Thao tác</Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map((row, i) => (
                                <Row key={row.id}>
                                    <Cell scope="row" align="center">
                                        {i + 1 + (props.pageProps.page * rowsPerPage)}
                                    </Cell>
                                    <Cell align="left">{row.code}</Cell>
                                    <Cell align="left">{row.name}</Cell>
                                    <Cell align="center">
                                        {Boolean(row.isActive) && <CheckIconDeActive />}
                                    </Cell>
                                    <Cell align="center">
                                        <Box display="flex" justifyContent="center">
                                            <IconButton color="primary" aria-label="edit" size="small"
                                                onClick={() => { props.modalEdit.openEditDialog(row) }}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton color="secondary" aria-label="delete" size="small"
                                                onClick={() => { props.deleteItem(row.id) }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Cell>
                                </Row>
                            ))}
                    </TableBody>
                </Table>
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination count={totalPage} defaultPage={1} siblingCount={1} variant="outlined"
                        size="medium"
                        color="primary" shape="rounded" showFirstButton showLastButton onChange={props.pageProps.onChangePage} />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">Số mẫu tin trên trang</Box>
                        <NativeSelect input={<CustomInput />} onChange={props.pageProps.onChangeRowsPerPage} >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">Mẫu tin {(props.pageProps.page * rowsPerPage) + 1} - {(props.pageProps.page * rowsPerPage) + rowsPerPage} / {props.pageProps.count}</Box>
                </Box>
            </TableContainer>
        </>
    );
}

