import { bootstrapHelperTextStyles, bootstrapInputStyles, bootstrapLabelStyles } from "./bootstrapInputStyles";

export const bootstrapTextFieldHook = {
    useLabel: bootstrapLabelStyles,
    useInput: bootstrapInputStyles,
    useHelperText: bootstrapHelperTextStyles,
};
// export const filledTextFieldHook = {
//     useInput: makeStyles(filledInputStyles),
//     useHelperText: makeStyles(filledHelperTextStyles),
// };
