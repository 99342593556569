
import AXIOS_INSTANCE from "../../../apis";
import { DanhMucDonViKT, SearchRequest } from "../../../apis/Common/model/CommonModel";
export class DonViKTService {
    constructor(){}
    search = (request : SearchRequest) => {
       return AXIOS_INSTANCE.post('don-vi-khen-thuong/search', request);
    }
    getByHoatDong = (hoatDong : boolean) => {
       return AXIOS_INSTANCE.get('don-vi-khen-thuong/get-by-hoat-dong/' + hoatDong);
    }
    addOrUpdate = (request : DanhMucDonViKT) => {
        return AXIOS_INSTANCE.post('don-vi-khen-thuong/addOrUpdate', request);
     }
     delete = (id: number) => {
      return AXIOS_INSTANCE.post('don-vi-khen-thuong/delete/'+ id);
    }
}
