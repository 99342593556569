import { useCallback, useEffect, useMemo, useState } from "react";
import api from "../apis";

export const useAxiosLoader = () => {
    const [counter, setCounter] = useState(0);
    const inc = useCallback(() => setCounter(counter => counter + 1), [setCounter]); // add to counter
    const dec = useCallback(() => setCounter(counter => counter - 1), [setCounter]); // remove from counter

    const interceptors = useMemo(() => ({
        request: (config: any) => (inc(), config),
        response: (response: any) => (dec(), response),
        error: (error: any) => (dec(), Promise.reject(error)),
    }), [inc, dec]); // create the interceptors

    useEffect(() => {
        // add request interceptors
        const reqInterceptor = api.interceptors.request.use(interceptors.request, interceptors.error);
        // add response interceptors
        const resInterceptor = api.interceptors.response.use(interceptors.response, interceptors.error);
        return () => {
            // remove all intercepts when done
            api.interceptors.request.eject(reqInterceptor);
            api.interceptors.response.eject(resInterceptor);
        };
    }, [interceptors]);

    return [counter > 0];
};
