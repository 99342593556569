import { lazy } from "react";
import { DanhMucChucDanhMgt } from "../pages/danhMuc/chucDanh/danhMucChucDanhMgt";
import { DonViKTComponent } from "../pages/danhMuc/donViKT/main";
import { DanhMucLinhVucMgt } from "../pages/danhMuc/linhVuc/danhMucLinhVucMgt";
import { DanhMucLoaiHinhMgt } from "../pages/danhMuc/loaiHinhKhenThuong/danhMucLoaiHinhKhenThuongMgt";
import { DanhMucPhuongThucMgt } from "../pages/danhMuc/phuongThuc/danhMucPhuongThucMgt";
import { DanhMucQuocTichMgt } from "../pages/danhMuc/quocTich/danhMucQuocTichMgt";
import { DanhMucTinhChatMgt } from "../pages/danhMuc/tinhChat/danhMucTinhChatMgt";
import { ImportDauKyComponent } from "../pages/importDauKy/ImportDauKyComponent";
import { DongBoCanBoCongChucComponent } from "../pages/quanTriHeThong/DongBoCanBo/DongBoCanBoCongChucComponent";
import { ThongKeTinhHinhCapPhep } from "../pages/thongKeCapQH/ThongKeTinhHinhCapPhep";
// use lazy for better code splitting, a.k.a. load faster
const DongBoDuLieuComponent = lazy(() => import("../pages/DongBoDuLieu/DongBoDuLieuComponent").then(({ DongBoDuLieuComponent }) => ({ default: DongBoDuLieuComponent })));
const DongBoMotCuaComponent = lazy(() => import("../pages/quanTriHeThong/DongBoMotCua/DongBoMotCuaComponent").then(({ DongBoMotCuaComponent }) => ({ default: DongBoMotCuaComponent })));
const PasswordManagement = lazy(() => import("../pages/user/passwordMgt/passwordManagement").then(({ PasswordManagement }) => ({ default: PasswordManagement })));
const UserProfile = lazy(() => import("../pages/user/profile/profile").then(({ UserProfile }) => ({ default: UserProfile })));
const UserManagement = lazy(() => import("../pages/user/mgt/userManagement").then(({ UserManagement }) => ({ default: UserManagement })));
const HoSoCuaToiMgt = lazy(() => import("../pages/hoSoCuaToi/hoSoCuaToiMgt").then(({ HoSoCuaToiMgt }) => ({ default: HoSoCuaToiMgt })));
const CauHinhNghiepVuMgt = lazy(() => import("../pages/quanTriHeThong/cauHinhNghiepVu/cauHinhNghiepVuMgt").then(({ CauHinhNghiepVuMgt }) => ({ default: CauHinhNghiepVuMgt })));
const MenuRoleManagement = lazy(() => import("../pages/quanTriHeThong/menu/menuRoleManagement").then(({ MenuRoleManagement }) => ({ default: MenuRoleManagement })));
const GroupRoleManagementComponent = lazy(() =>
    import("../pages/quanTriHeThong/nhomVaiTro/groupRoleManagementComponent").then(({ GroupRoleManagementComponent }) => ({ default: GroupRoleManagementComponent }))
);
const PhanQuyenNguoiDungComponent = lazy(() =>
    import("../pages/quanTriHeThong/phanQuyenNguoiDung/phanQuyenNguoiDungComponent").then(({ PhanQuyenNguoiDungComponent }) => ({ default: PhanQuyenNguoiDungComponent }))
);
const DanhMucBieuMauMgt = lazy(() => import("../pages/quanTriHeThong/danhMucBieuMau/index").then(({ DanhMucBieuMau }) => ({ default: DanhMucBieuMau })));
const RoleManagementComponent = lazy(() => import("../pages/quanTriHeThong/vaiTro/roleManagementComponent").then(({ RoleManagementComponent }) => ({ default: RoleManagementComponent })));
const DanhMucHinhThucMgt = lazy(() => import("../pages/danhMuc/hinhThuc/danhMucHinhThucMgt").then(({ DanhMucHinhThucMgt }) => ({ default: DanhMucHinhThucMgt })));
const DanhMucDanhHieuMgt = lazy(() => import("../pages/danhMuc/danhHieu/danhMucDanhHieuMgt").then(({ DanhMucDanhHieuMgt }) => ({ default: DanhMucDanhHieuMgt })));
const DanhMucDonViKTMgt = lazy(() => import("../pages/danhMuc/donViKhenThuong/danhMucDonViKTMgt").then(({ DanhMucDonViKTMgt }) => ({ default: DanhMucDonViKTMgt })));
const CapNhatQuyetDinhKT = lazy(() => import("../pages/capNhatQuyetDinhKT/index").then(({ CapNhatQuyetDinhKT }) => ({ default: CapNhatQuyetDinhKT })));
const TraCuuQuyetDinhKT = lazy(() => import("../pages/traCuu/index").then(({ TraCuu }) => ({ default: TraCuu })));
const TraCuuQuyetDinhKTQH = lazy(() => import("../pages/traCuu/index2").then(({ TraCuu }) => ({ default: TraCuu })));

const ChuyenNganhSo1 = lazy(() => import("../pages/chuyenNganh/chuyenNganhSo1/index").then(({ ChuyenNganhSo1 }) => ({ default: ChuyenNganhSo1 })));
const ChuyenNganhSo2 = lazy(() => import("../pages/chuyenNganh/chuyenNganhSo2/index").then(({ ChuyenNganhSo2 }) => ({ default: ChuyenNganhSo2 })));
const ChuyenNganhSo3 = lazy(() => import("../pages/chuyenNganh/chuyenNganhSo3/index").then(({ ChuyenNganhSo3 }) => ({ default: ChuyenNganhSo3 })));
const ChuyenNganhSo4 = lazy(() => import("../pages/chuyenNganh/chuyenNganhSo4/index").then(({ ChuyenNganhSo4 }) => ({ default: ChuyenNganhSo4 })));
const ChuyenNganhSo5 = lazy(() => import("../pages/chuyenNganh/chuyenNganhSo5/index").then(({ ChuyenNganhSo5 }) => ({ default: ChuyenNganhSo5 })));
const ChuyenNganhSo6 = lazy(() => import("../pages/chuyenNganh/chuyenNganhSo6/index").then(({ ChuyenNganhSo6 }) => ({ default: ChuyenNganhSo6 })));
const ChuyenNganhSo7 = lazy(() => import("../pages/chuyenNganh/chuyenNganhSo7/index").then(({ ChuyenNganhSo7 }) => ({ default: ChuyenNganhSo7 })));
const ChuyenNganhSo8 = lazy(() => import("../pages/chuyenNganh/chuyenNganhSo8/index").then(({ ChuyenNganhSo8 }) => ({ default: ChuyenNganhSo8 })));
const ChuyenNganhSo9 = lazy(() => import("../pages/chuyenNganh/chuyenNganhSo9/index").then(({ ChuyenNganhSo9 }) => ({ default: ChuyenNganhSo9 })));
const ChuyenNganhDKSo1 = lazy(() => import("../pages/chuyenNganhDauKy/chuyenNganhSo1/index").then(({ ChuyenNganhDKSo1 }) => ({ default: ChuyenNganhDKSo1 })));
const ChuyenNganhDKSo2 = lazy(() => import("../pages/chuyenNganhDauKy/chuyenNganhSo2/index").then(({ ChuyenNganhDKSo2 }) => ({ default: ChuyenNganhDKSo2 })));
const ChuyenNganhDKSo3 = lazy(() => import("../pages/chuyenNganhDauKy/chuyenNganhSo3/index").then(({ ChuyenNganhDKSo3 }) => ({ default: ChuyenNganhDKSo3 })));
const ChuyenNganhDKSo4 = lazy(() => import("../pages/chuyenNganhDauKy/chuyenNganhSo4/index").then(({ ChuyenNganhDKSo4 }) => ({ default: ChuyenNganhDKSo4 })));
const ChuyenNganhDKSo5 = lazy(() => import("../pages/chuyenNganhDauKy/chuyenNganhSo5/index").then(({ ChuyenNganhDKSo5 }) => ({ default: ChuyenNganhDKSo5 })));
const ChuyenNganhDKSo6 = lazy(() => import("../pages/chuyenNganhDauKy/chuyenNganhSo6/index").then(({ ChuyenNganhDKSo6 }) => ({ default: ChuyenNganhDKSo6 })));
const ChuyenNganhDKSo7 = lazy(() => import("../pages/chuyenNganhDauKy/chuyenNganhSo7/index").then(({ ChuyenNganhDKSo7 }) => ({ default: ChuyenNganhDKSo7 })));
const ChuyenNganhDKSo8 = lazy(() => import("../pages/chuyenNganhDauKy/chuyenNganhSo8/index").then(({ ChuyenNganhDKSo8 }) => ({ default: ChuyenNganhDKSo8 })));
const ChuyenNganhDKSo9 = lazy(() => import("../pages/chuyenNganhDauKy/chuyenNganhSo9/index").then(({ ChuyenNganhDKSo9 }) => ({ default: ChuyenNganhDKSo9 })));
const BaoCaoThiDuaKT = lazy(() => import("../pages/baoCaoThiDuaKT/index").then(({ BaoCaoThiDuaKT }) => ({ default: BaoCaoThiDuaKT })));
const ThongKeThiDuaKT = lazy(() => import("../pages/thongKeThiDuaKT/index").then(({ ThongKeThiDuaKT }) => ({ default: ThongKeThiDuaKT })));
const ThongTinChiTiet = lazy(() => import("../pages/traCuu/thongTinChiTiet").then(({ ThongTinChiTiet }) => ({ default: ThongTinChiTiet })));
const HuongDanSuDungComponent = lazy(() =>
import('../pages/quanTriHeThong/huongDanSuDung/huongDanSuDungComponent').then(({ HuongDanSuDungComponent }) => ({ default: HuongDanSuDungComponent })));
export interface RoutePropsI {
    ten: string;
    giaoDien: any;
    duongDan: string;
    routes?: RoutePropsI[];
}

export const ROUTE_CN: RoutePropsI[] = [
    {
        ten: "Tặng cờ thi đua cấp bộ, ban, ngành, đoàn thể trung ương, tỉnh, thành phố trực thuộc Trung ương theo đợt hoặc chuyên đề",
        giaoDien: ChuyenNganhSo1,
        duongDan: "/chuyen-nganh/so-1",
    },
    {
        ten: "Tặng cờ thi đua cấp bộ, ngành, đoàn thể trung ương, tỉnh, thành phố trực thuộc trung ương",
        giaoDien: ChuyenNganhSo2,
        duongDan: "/chuyen-nganh/so-2",
    },
    {
        ten: "Tặng danh hiệu Chiến sĩ thi đua cấp bộ, ban, ngành đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương",
        giaoDien: ChuyenNganhSo3,
        duongDan: "/chuyen-nganh/so-3",
    },
    {
        ten: "Tặng danh hiệu Tập thể Lao động xuất sắc",
        giaoDien: ChuyenNganhSo4,
        duongDan: "/chuyen-nganh/so-4",
    },
    {
        ten: "Tặng thưởng Bằng khen cấp bộ, ban, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương cho gia đình",
        giaoDien: ChuyenNganhSo5,
        duongDan: "/chuyen-nganh/so-5",
    },
    {
        ten: "Tặng thưởng Bằng khen cấp bộ, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương theo đợt hoặc chuyên đề",
        giaoDien: ChuyenNganhSo6,
        duongDan: "/chuyen-nganh/so-6",
    },
    {
        ten: "Tặng thưởng Bằng khen cấp bộ, ban, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương về thành tích đối ngoại",
        giaoDien: ChuyenNganhSo7,
        duongDan: "/chuyen-nganh/so-7",
    },
    {
        ten: "Tặng thưởng Bằng khen cấp bộ, ban, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương về thành tích đột xuất",
        giaoDien: ChuyenNganhSo8,
        duongDan: "/chuyen-nganh/so-8",
    },
    {
        ten: "Tặng thưởng Bằng khen cấp bộ, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương",
        giaoDien: ChuyenNganhSo9,
        duongDan: "/chuyen-nganh/so-9",
    },
    {
        ten: "Thông tin chi tiết",
        giaoDien: ThongTinChiTiet,
        duongDan: "/thong-tin-chi-tiet",
    },
    {
        ten: "Nhập đầu kỳ tặng cờ thi đua cấp bộ, ban, ngành, đoàn thể trung ương, tỉnh, thành phố trực thuộc Trung ương theo đợt hoặc chuyên đề",
        giaoDien: ChuyenNganhDKSo1,
        duongDan: "/chuyen-nganh-dau-ky/so-1",
    },
    {
        ten: "Nhập đầu kỳ tặng cờ thi đua cấp bộ, ngành, đoàn thể trung ương, tỉnh, thành phố trực thuộc trung ương",
        giaoDien: ChuyenNganhDKSo2,
        duongDan: "/chuyen-nganh-dau-ky/so-2",
    },
    {
        ten: "Nhập đầu kỳ tặng danh hiệu Chiến sĩ thi đua cấp bộ, ban, ngành đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương",
        giaoDien: ChuyenNganhDKSo3,
        duongDan: "/chuyen-nganh-dau-ky/so-3",
    },
    {
        ten: "Nhập đầu kỳ tặng danh hiệu Tập thể Lao động xuất sắc",
        giaoDien: ChuyenNganhDKSo4,
        duongDan: "/chuyen-nganh-dau-ky/so-4",
    },
    {
        ten: "Nhập đầu kỳ tặng thưởng Bằng khen cấp bộ, ban, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương cho gia đình",
        giaoDien: ChuyenNganhDKSo5,
        duongDan: "/chuyen-nganh-dau-ky/so-5",
    },
    {
        ten: "Nhập đầu kỳ tặng thưởng Bằng khen cấp bộ, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương theo đợt hoặc chuyên đề",
        giaoDien: ChuyenNganhDKSo6,
        duongDan: "/chuyen-nganh-dau-ky/so-6",
    },
    {
        ten: "Nhập đầu kỳ tặng thưởng Bằng khen cấp bộ, ban, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương về thành tích đối ngoại",
        giaoDien: ChuyenNganhDKSo7,
        duongDan: "/chuyen-nganh-dau-ky/so-7",
    },
    {
        ten: "Nhập đầu kỳ tặng thưởng Bằng khen cấp bộ, ban, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương về thành tích đột xuất",
        giaoDien: ChuyenNganhDKSo8,
        duongDan: "/chuyen-nganh-dau-ky/so-8",
    },
    {
        ten: "Nhập đầu kỳ tặng thưởng Bằng khen cấp bộ, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương",
        giaoDien: ChuyenNganhDKSo9,
        duongDan: "/chuyen-nganh-dau-ky/so-9",
    },
];

export const ROUTE_PAGE_HOSOCUATOI: RoutePropsI ={
    ten: "HỒ SƠ CỦA TÔI",
    duongDan: '/ho-so-cua-toi',
    giaoDien: HoSoCuaToiMgt,
}

export const ROUTE_DAUKY_CN: RoutePropsI[] = [
    {
        ten: "Tặng cờ thi đua cấp bộ, ban, ngành, đoàn thể trung ương, tỉnh, thành phố trực thuộc Trung ương theo đợt hoặc chuyên đề",
        giaoDien: ChuyenNganhDKSo1,
        duongDan: "/chuyen-nganh-dau-ky/so-1",
    },
    {
        ten: "Tặng cờ thi đua cấp bộ, ngành, đoàn thể trung ương, tỉnh, thành phố trực thuộc trung ương",
        giaoDien: ChuyenNganhDKSo2,
        duongDan: "/chuyen-nganh-dau-ky/so-2",
    },
    {
        ten: "Tặng danh hiệu Chiến sĩ thi đua cấp bộ, ban, ngành đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương",
        giaoDien: ChuyenNganhDKSo3,
        duongDan: "/chuyen-nganh-dau-ky/so-3",
    },
    {
        ten: "Tặng danh hiệu Tập thể Lao động xuất sắc",
        giaoDien: ChuyenNganhDKSo4,
        duongDan: "/chuyen-nganh-dau-ky/so-4",
    },
    {
        ten: "Tặng thưởng Bằng khen cấp bộ, ban, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương cho gia đình",
        giaoDien: ChuyenNganhDKSo5,
        duongDan: "/chuyen-nganh-dau-ky/so-5",
    },
    {
        ten: "Tặng thưởng Bằng khen cấp bộ, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương theo đợt hoặc chuyên đề",
        giaoDien: ChuyenNganhDKSo6,
        duongDan: "/chuyen-nganh-dau-ky/so-6",
    },
    {
        ten: "Tặng thưởng Bằng khen cấp bộ, ban, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương về thành tích đối ngoại",
        giaoDien: ChuyenNganhDKSo7,
        duongDan: "/chuyen-nganh-dau-ky/so-7",
    },
    {
        ten: "Tặng thưởng Bằng khen cấp bộ, ban, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương về thành tích đột xuất",
        giaoDien: ChuyenNganhDKSo8,
        duongDan: "/chuyen-nganh-dau-ky/so-8",
    },
    {
        ten: "Tặng thưởng Bằng khen cấp bộ, ngành, đoàn thể Trung ương, tỉnh, thành phố trực thuộc Trung ương",
        giaoDien: ChuyenNganhDKSo9,
        duongDan: "/chuyen-nganh-dau-ky/so-9",
    },
];

export const ROUTE_PAGE: RoutePropsI[] = [
    {
        ten: 'Hướng dẫn sử dụng',
        giaoDien: HuongDanSuDungComponent,
        duongDan: '/huong-dan-su-dung',
    },
    ROUTE_PAGE_HOSOCUATOI,
    {
        ten: "Tra cứu quyết định khen thưởng",
        duongDan: "/tra-cuu-quyet-dinh-khen-thuong",
        giaoDien: TraCuuQuyetDinhKT,
    },
    {
        ten: "Tra cứu quyết định khen thưởng",
        duongDan: "/tra-cuu-quyet-dinh-khen-thuong-quan-huyen",
        giaoDien: TraCuuQuyetDinhKTQH,
    },
    {
        ten: "Cập nhật quyết định KT",
        duongDan: "/cap-nhat-quyet-dinh-khen-thuong",
        giaoDien: CapNhatQuyetDinhKT,
    },
    {
        ten: "Báo cáo thi đua khen thưởng",
        duongDan: "/bao-cao-thi-dua-khen-thuong",
        giaoDien: BaoCaoThiDuaKT,
    },
    {
        ten: "Thống kê thi đua khen thưởng",
        duongDan: "/thong-ke-thi-dua-khen-thuong",
        giaoDien: ThongKeThiDuaKT,
    },
    {
        ten: "Thống kê thi đua khen thưởng theo quận huyện",
        duongDan: "/thong-ke-thi-dua-khen-thuong-theo-quan-huyen",
        giaoDien: ThongKeTinhHinhCapPhep,
    },
    {
        ten: "Cấu hình nghiệp vụ",
        giaoDien: CauHinhNghiepVuMgt,
        duongDan: "/cau-hinh-nghiep-vu",
    },
    {
        ten: "Phân quyền người dùng",
        giaoDien: PhanQuyenNguoiDungComponent,
        duongDan: "/phan-quyen-nguoi-dung",
    },
    {
        ten: "Quản lý vai trò",
        giaoDien: RoleManagementComponent,
        duongDan: "/quan-ly-vai-tro",
    },
    {
        ten: "Quản lý nhóm vai trò",
        giaoDien: GroupRoleManagementComponent,
        duongDan: "/quan-ly-nhom-vai-tro",
    },
    {
        ten: "Quản lý menu",
        giaoDien: MenuRoleManagement,
        duongDan: "/quan-ly-menu",
    },
    {
        ten: "DM hình thức",
        giaoDien: DanhMucHinhThucMgt,
        duongDan: "/quan-ly-dm-hinh-thuc",
    },
    {
        ten: "DM loại hình khen thưởng",
        giaoDien: DanhMucLoaiHinhMgt,
        duongDan: "/quan-ly-dm-loai-hinh-khen-thuong",
    },
    {
        ten: "DM chức danh",
        giaoDien: DanhMucChucDanhMgt,
        duongDan: "/quan-ly-dm-chuc-danh",
    },
    {
        ten: "DM phương thức",
        giaoDien: DanhMucPhuongThucMgt,
        duongDan: "/quan-ly-dm-phuong-thuc",
    },
    {
        ten: "DM quốc tịch",
        giaoDien: DanhMucQuocTichMgt,
        duongDan: "/quan-ly-dm-quoc-tich",
    },
    {
        ten: "DM tính chất",
        giaoDien: DanhMucTinhChatMgt,
        duongDan: "/quan-ly-dm-tinh-chat",
    },
    {
        ten: "DM lĩnh vực",
        giaoDien: DanhMucLinhVucMgt,
        duongDan: "/quan-ly-dm-linh-vuc",
    },
    {
        ten: "DM danh hiệu",
        giaoDien: DanhMucDanhHieuMgt,
        duongDan: "/quan-ly-dm-danh-hieu",
    },
    {
        ten: "DM đơn vị khen thưởng",
        giaoDien: DonViKTComponent,
        duongDan: "/quan-ly-dm-don-vi-kt",
    },
    {
        ten: "DM Biểu mẫu",
        giaoDien: DanhMucBieuMauMgt,
        duongDan: "/quan-ly-dm-bieu-mau",
    },
    {
        ten: 'Đồng bộ một cửa',
        giaoDien: DongBoMotCuaComponent,
        duongDan: '/dong-bo-mot-cua',
    },
    {
        ten: 'Đồng bộ dữ liệu',
        giaoDien: DongBoDuLieuComponent,
        duongDan: '/dong-bo-du-lieu',
    },
    {
        ten: "Import đầu ky",
        giaoDien: ImportDauKyComponent,
        duongDan: "/import-dau-ky",
    },
    {
        ten: 'Quản trị người dùng',
        duongDan: '/quan-tri-nguoi-dung',
        giaoDien: UserManagement,
    },
    {
        ten: 'Đồng bộ người dùng',
        duongDan: '/dong-bo-nguoi-dung',
        giaoDien: DongBoCanBoCongChucComponent,
    },
];

export const ROUTE_ADMIN: RoutePropsI[] = [

];
