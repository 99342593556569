import {
    Button,
    createStyles,
    Grid,
    InputBase,
    InputLabel,
    Link,
    makeStyles,
    NativeSelect,
    Snackbar,
    SvgIcon,
    TextField,
    Theme
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { OptionYears } from "../../components/options/optionCommons";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { OverlayLoading } from "../../components/overlayLoading/overlayLoading";
import { AlertMessage, CustomAlert } from "../../components/commons/alert";
import { bootstrapTextFieldHook } from "../../components/commons/input/bootstrap";
import { useDanhMucKhenThuong } from "../../hooks/useDanhMucKhenThuong";
import { Type } from "../../apis/danhMuc/danhMucKhenThuong";
import { DanhSachThongKeTinhHinhCapPhep } from "./DanhSachThongKeCapPhep";
import { BaoCaoThongKeKhenThuongService, ThongKeKhenThuongTheoDonViDTO } from "../../apis/chuyenNganh/baoCaoThongKeKhenThuongService";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { hideLoading, LoadingAction, showLoading } from "../../redux/actions/applicationAction";
import { createDownloadType, downLoadFile } from "../../apis/Common/exportUtil";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
            "margin-top": " 25px !important;",
            "margin-bottom": "0px"
        },
    }),
);

export const ThongKeTinhHinhCapPhep = () => {
    const classes = useStyles();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [kyThongKe, setKyThongKe] = useState<number>(1);
    const [year, setYears] = useState<number>(new Date().getFullYear());
    const [tuNgay, setTuNgay] = useState<number>(0);
    const [denNgay, setDenNgay] = useState<number>(0);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const [modeTK, setModeTK] = useState<string>("0");
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();

    const [rows, setRows] = useState<ThongKeKhenThuongTheoDonViDTO[]>([]);
    const service = new BaoCaoThongKeKhenThuongService();

    useEffect(() => {
        loadData(kyThongKe, year, tuNgay, denNgay, modeTK);
    }, []);
    const loadData = async (kyThongKe: number, year: number, tuNgay: number, denNgay: number, modeTK: string) => {
        dispatchLoading(showLoading());
        await service.thongKeTheoDonViKhenThuong(modeTK, kyThongKe == 1 ? year : 0, tuNgay, denNgay).then((res) => {
            console.log(res)
            setRows(res);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    };
    const downloadFile = async (downloadType: "MSWORD" | "EXCEL" | "PDF") => {
        await service.exportThongKeTheoDonViKhenThuong(modeTK, kyThongKe == 1 ? year : 0, tuNgay, denNgay, downloadType).then((res) => {
            downLoadFile(res.data, createDownloadType(downloadType), 'THỐNG KÊ VIỆC TẶNG BẰNG KHEN DANH HIỆU THI ĐUA CẤP QUẬN HUYỆN');
        });
    };
    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }));
    }
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>THỐNG KÊ VIỆC TẶNG BẰNG KHEN DANH HIỆU THI ĐUA CẤP QUẬN HUYỆN</span>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Kỳ thống kê</InputLabel>
                    <NativeSelect fullWidth disableUnderline name="kyThongKe" value={kyThongKe}
                        input={<InputBase fullWidth classes={inputStyles} />}
                        onChange={(e: any) => {
                            setKyThongKe(e.target.value);
                            loadData(e.target.value, year, tuNgay, denNgay, modeTK);
                        }}>
                        <option value="1">Năm</option>
                        <option value="2">Khoảng thời gian</option>
                    </NativeSelect>
                </Grid>
                <Grid item xs={3} className="grid-item" hidden={kyThongKe == 2}>
                    <InputLabel classes={labelStyles}>Năm </InputLabel>
                    <NativeSelect
                        name="year"
                        value={year}
                        onChange={(e: any) => {
                            setYears(e.target.value);
                            loadData(kyThongKe, e.target.value, tuNgay, denNgay, modeTK);
                        }}
                        input={<InputBase fullWidth classes={inputStyles} />}
                    >
                        <OptionYears />
                    </NativeSelect>
                </Grid>
                <Grid item xs={3} className="grid-item" hidden={kyThongKe == 1}>
                    <InputLabel classes={labelStyles}>Từ ngày</InputLabel>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            autoOk={true}
                            showTodayButton={true}
                            placeholder="dd/MM/yyyy"
                            value={tuNgay != 0 ? new Date(tuNgay) : null}
                            format="DD/MM/YYYY"
                            fullWidth
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                            onChange={(date: any, value: any) => {
                                setTuNgay(date?.toDate()?.getTime());
                                loadData(kyThongKe, year, date?.toDate()?.getTime(), denNgay, modeTK);
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3} className="grid-item" hidden={kyThongKe == 1}>
                    <InputLabel classes={labelStyles}>Đến ngày</InputLabel>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            autoOk={true}
                            showTodayButton={true}
                            placeholder="dd/MM/yyyy"
                            value={denNgay > 0 ? new Date(denNgay) : null}
                            format="DD/MM/YYYY"
                            fullWidth
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                            onChange={(date: any, value: any) => {
                                setDenNgay(date?.toDate()?.getTime());
                                loadData(kyThongKe, year, tuNgay, date?.toDate()?.getTime(), modeTK);
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3} className="grid-item" hidden={kyThongKe == 1}>
                </Grid>
                <Grid item xs={6} className="grid-item" hidden={kyThongKe == 2}>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Loại thống kê</InputLabel>
                    <NativeSelect onChange={(e) => {
                        setModeTK(e.target.value);
                        loadData(kyThongKe, year, tuNgay, denNgay, e.target.value);
                    }} input={<InputBase fullWidth classes={inputStyles} />}>
                        <option value={0}>Thống kê theo loại đối tượng</option>
                        <option value={1}>Thống kê theo loại hình thức</option>
                        <option value={2}>Thống kê theo loại danh hiệu</option>
                    </NativeSelect>
                </Grid>
                <Grid item xs={9} className="grid-item" container justify="flex-end">
                    <Link title="Tải xuống" href="#" onClick={() => {
                        downloadFile('MSWORD');
                    }}>
                        <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#007bff" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z" />
                        </SvgIcon>
                    </Link>
                    <Link title="Tải xuống" href="#" onClick={() => {
                        downloadFile('PDF');
                    }}>
                        <SvgIcon fontSize="large">
                            <path fill="#dc3545" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z" />
                        </SvgIcon>
                    </Link>
                    <Link title="Tải xuống" href="#" onClick={() => {
                        downloadFile('EXCEL');
                    }}>
                        <SvgIcon fontSize="large">
                            <path fill="#28a745" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
                        </SvgIcon>
                    </Link>
                </Grid>
                <Grid item xs={12} className="grid-item">
                    <DanhSachThongKeTinhHinhCapPhep data={rows} modeTK={modeTK} />
                </Grid>
            </Grid>
            <OverlayLoading />
            <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
            </Snackbar>
        </div >
    )
}

