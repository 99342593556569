import { Box, Button, CardMedia, Checkbox, Snackbar } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import React, { Dispatch, useState } from "react";
import { useDispatch } from "react-redux";
import { resetAuthenticatedSession } from "../../actions/session";
import { UserManagementService } from "../../apis/userMgt/userManagementService";
import { bootstrapTextFieldHook } from "../../components/commons/input/bootstrap";
import { CustomInput } from "../../components/commons/input/customInput";
import { OverlayLoading } from "../../components/overlayLoading/overlayLoading";
import { getString } from "../../helpers/localStorage";
import { hideLoading, LoadingAction, showLoading } from "../../redux/actions/applicationAction";
import StringUtil from "../../utils/stringUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            backgroundColor: "#367fa9",
            color: "#fff",
            fontWeight: 700,
            "&:hover": {
                backgroundColor: "#367fa9",
            },
        },
        customCheckbox: {
            color: grey[50],
            paddingLeft: 0,
        },
    }),
);
export const Login = () => {
    const classes = useStyles();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const labelStyles = bootstrapTextFieldHook.useLabel();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [alertError, setAlertError] = useState(false);
    const [checkLogin, setCheckLogin] = useState(false);
    const [messageError, setMessageError] = useState("");
    React.useEffect(() => {
        resetAuthenticatedSession();
        new UserManagementService().getSession();
        if (localStorage.getItem("CHECKLOGIN") === "true") {
            setUsername(getString("USERNAME"));
            setPassword(getString("PASSWORD"));
            setCheckLogin(Boolean(localStorage.getItem("CHECKLOGIN")));
        }
    }, []);
    const handleLogin = () => {
        if (StringUtil.isNullOrEmty(username) || StringUtil.isNullOrEmty(password)) {
            setMessageError("Tên đăng nhập và mật khẩu bắt buộc nhập!");
            setAlertError(true);
            return;
        }
        dispatchLoading(showLoading());
        new UserManagementService()
            .doAuthenticate({ userName: username, password: password })
            .then((p) => {
                if (p.isSuccess) {
                    let userInfoDecrypted = p.data;
                    localStorage.setItem("C_USER_DATA", JSON.stringify(userInfoDecrypted));
                    localStorage.setItem("ADMIN_SSID", userInfoDecrypted.sessionValue);
                    localStorage.setItem("C_USER", userInfoDecrypted.userId);
                    localStorage.setItem("USERNAME", username);
                    localStorage.setItem("PASSWORD", password);
                    localStorage.setItem("CHECKLOGIN", checkLogin == false ? "false" : "true");
                    localStorage.setItem("PRIVATE_LOGIN", "1");
                    window.location.href = '/ho-so-cua-toi';
                } else {
                    setMessageError(p.message);
                    setAlertError(true);
                }
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const onKeyEnter = (e: any) => {
        if (e.charCode && e.charCode === 13) {
            handleLogin();
        }
    };

    return (
        <CardMedia image={process.env.PUBLIC_URL + "/images/background-login-page.jpg"}>
            <Box display="flex" flexDirection="column" style={{ color: "#fff", height: "100vh" }}>
                <Box display="flex" flexDirection="row" alignItems="center" paddingTop="15px" paddingLeft="20px" style={{ textTransform: "uppercase" }}>
                    <img src={process.env.PUBLIC_URL + "/images/login-logo.png"} width="80" height="80" alt="login-logo" />
                    <Box display="flex" marginLeft="20px" flexDirection="column">
                        <Box component="span" fontSize="16px" marginBottom="5px">
                            Chính quyền điện tử
                        </Box>
                        <Box component="span" fontSize="22px" fontWeight="700">
                            Thành phố cần thơ
                        </Box>
                    </Box>
                </Box>
                <Box style={{ margin: "110px auto 0px", backgroundColor: "#3a3939ae", fontSize: "14px", padding: "15px 15px" }}>
                    <Box display="flex" flexDirection="column">
                        <Box component="span" fontWeight="700" margin="5px">
                            Tên đăng nhập
                        </Box>
                        <CustomInput value={username} onChange={(e) => setUsername(e.target.value)} style={{ marginLeft: "0px" }} />
                        <Box component="span" fontWeight="700" margin="5px">
                            Mật khẩu
                        </Box>
                        <CustomInput type="password" value={password} onKeyPress={onKeyEnter} onChange={(e) => setPassword(e.target.value)} style={{ marginLeft: "0px" }} />
                        <Box display="flex" alignItems="center" fontWeight="400">
                            <Checkbox className={classes.customCheckbox} color="default" size="small" disableRipple={true} inputProps={{ "aria-label": "uncontrolled-checkbox" }} 
                            checked={checkLogin} onChange={(e) => setCheckLogin(!checkLogin)}/>
                            <span>Ghi nhớ đăng nhâp</span>
                            <Box component="a" marginLeft="50px" fontStyle="italic" style={{ color: "#6AD2FF" }}>
                                Quên mật khẩu?
                            </Box>
                        </Box>
                        <Button className={classes.button} disableRipple={true} variant="contained" onClick={() => handleLogin()}>
                            ĐĂNG NHẬP
                        </Button>
                    </Box>
                </Box>
                <Box flexGrow="1" display="flex" flexDirection="column" justifyContent="flex-end">
                    <span style={{ padding: "15px 20px", backgroundColor: "#55555566", textTransform: "uppercase", fontSize: "13px" }}>&copy; Bản quyền thuộc sở thông tin truyền thông cần thơ</span>
                </Box>
                <Snackbar open={alertError} onClose={() => setAlertError(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                    <Alert onClose={() => setAlertError(false)} severity="error">
                        {messageError}
                    </Alert>
                </Snackbar>
                <OverlayLoading />
            </Box>
        </CardMedia>
    );
};
