import axios from "../index";
import { PageRequest, BaseService, Pagination, BaseResponse } from "../baseService";


export interface DanhMucLinhVucDTO {
    id: string;
    code: string;
    name: string;
    description: string;
    isActive: boolean;
}

export interface DanhMucLinhVucRequest extends PageRequest {
    isActive?: Boolean;
}
export class DanhMucLinhVucService extends BaseService {

    getDMLinhVuc = async (request: DanhMucLinhVucRequest): Promise<Pagination<DanhMucLinhVucDTO[]>> => {
        let result = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        } as Pagination<DanhMucLinhVucDTO[]>;
        await axios.get('/danh-muc-linh-vuc/search', {
            params: request
        }).then(response => {
            if (response.data.error_code === 'SUCCESSFUL' && response.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content
                }
            }
        }).catch();
        return result;
    }

    insertOrUpdate = async (request: DanhMucLinhVucDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios.post('/danh-muc-linh-vuc/insertOrUpdate', JSON.stringify(request), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_message
            }
        }).catch(() => result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' });
        return result;
    }

    deleteById = async (id: string): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios.post('/danh-muc-linh-vuc/delete', JSON.stringify({ id: id }), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_message
            }
        }).catch(() => result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' });
        return result;
    }


}
