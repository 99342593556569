import { Box, Checkbox, Dialog, DialogContent, Grid, InputBase, InputLabel, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { DialogTitle } from "../../../components/commons/dialog/dialogCommons";
import React, { useEffect, useState } from 'react';
import { BaseResponse } from '../../../apis/baseService';
import { DanhMucPhuongThucDTO, DanhMucPhuongThucService } from '../../../apis/danhMuc/danhMucPhuongThuc';
import StringUtil from '../../../utils/stringUtils';
import { bootstrapTextFieldHook } from '../../../components/commons/input/bootstrap';


interface IModalProps {
    onSubmitData: (resp: BaseResponse) => void;
    onDialogClose: () => void;
    openDialog: boolean;
    itemEdit: DanhMucPhuongThucDTO;
}

type ErrorValidation = {
    name: string;
    code: string;
}

export const DMPhuongThucEditDialog = (props: IModalProps) => {
    const { onSubmitData } = props;
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [openDialog, setOpenDialog] = useState(false);
    const [itemEditDTO, setItemEditDTO] = useState<DanhMucPhuongThucDTO>({} as DanhMucPhuongThucDTO);
    const [errors, setErrors] = useState<ErrorValidation>({} as ErrorValidation);

    useEffect(() => {
        setItemEditDTO(props.itemEdit);
        setOpenDialog(props.openDialog);
    }, [props.itemEdit, props.openDialog]);

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setItemEditDTO(itemEditDTO => ({
            ...itemEditDTO,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value
        }));
    }



    function validateFrom(): boolean {
        let errorValidation = {} as ErrorValidation;
        if (StringUtil.isNullOrEmty(itemEditDTO.name)) {
            errorValidation.name = 'Tên phương thức bắt buộc nhập';
        } else if (itemEditDTO.name.trim().length > 255) {
            errorValidation.name = 'Tên phương thức không vượt quá 255 ký tự';
        }
        if (StringUtil.isNullOrEmty(itemEditDTO.code)) {
            errorValidation.code = 'Mã bắt buộc nhập';
        } 
        
        setErrors(errorValidation);
        return Object.keys(errorValidation).length === 0
    }

    const submitData = async () => {
        if (validateFrom()) {
            await new DanhMucPhuongThucService().insertOrUpdate(itemEditDTO).then(res => {
                onSubmitData(res);
            });
        }
    }

    const onResetData = () => {
        setItemEditDTO(itemEditDTO => ({
            ...itemEditDTO,
            name: '',
            description: '',
            isActive: false
        }));
    }

    return (
        <>
            <Dialog onClose={props.onDialogClose} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={openDialog}>
                <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                    {itemEditDTO.id ? 'Cập nhật ' : 'Thêm mới '} {'phương thức'}
                </DialogTitle>
                <DialogContent dividers>
                    <Box padding={0}>
                        <Grid container>
                        <Grid item xs={12} className="grid-item">
                                <TextField fullWidth
                                    label={'Mã'} required name="code"
                                    value={itemEditDTO.code} onChange={handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    error={Boolean(errors.code)}
                                    InputProps={{
                                        disableUnderline: true, classes: inputStyles,
                                    }}
                                    FormHelperTextProps={{ className: '-error', classes: helperTextStyles, }}
                                    helperText={Boolean(errors.code) && errors.code}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <TextField fullWidth
                                    label={'Tên phương thức'} required name="name"
                                    value={itemEditDTO.name} onChange={handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    error={Boolean(errors.name)}
                                    InputProps={{
                                        disableUnderline: true, classes: inputStyles,
                                    }}
                                    FormHelperTextProps={{ className: '-error', classes: helperTextStyles, }}
                                    helperText={Boolean(errors.name) && errors.name}
                                />
                            </Grid>

                            <Grid item xs={12} className="grid-item">
                                <InputLabel classes={labelStyles}>Mô tả</InputLabel>
                                <InputBase fullWidth rows={3} multiline classes={inputStyles}
                                    value={itemEditDTO.description} name="description" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} className="grid-item" style={{ paddingLeft: 0 }}>
                                <Checkbox name="isActive" checked={itemEditDTO.isActive} color="default" size="small"
                                    value={itemEditDTO.isActive} onChange={handleChange} /> Hoạt động
                            </Grid>
          

                            <Grid item xs={12} className="grid-item">
                                <Box marginTop="5px" className="button-group">
                                    <Button variant="contained" color="primary" disableElevation
                                        onClick={submitData}>
                                        Lưu
                                    </Button>
                                    <Button variant="contained" color="default" onClick={onResetData} disableElevation>
                                        Xóa
                                    </Button>
                                    <Button variant="contained" color="default" disableElevation
                                        onClick={props.onDialogClose}>
                                        Hủy
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}