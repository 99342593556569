export type MenuAction = {
    type?: "SUBMENU_ADD_CHIL" | "MENU" | "LOAD_MENU" | "SELECT_OTHER_MENU" | "SELECT_OTHER_MENU_CHIL" | "RESET";
    id?: number;
    isSupperAdmin?: boolean;
    parentID?: number;
    length?: number;
};

export const selectMenu = (data: MenuAction): MenuAction => {
    return {
        type: "MENU",
        id: data.id,
    };
};

export const selectSubMenu = (data: MenuAction): MenuAction => {
    return {
        type: "SUBMENU_ADD_CHIL",
        id: data.id,
        parentID: data.parentID,
        length: data.length,
    };
};

export const selectOtherMenu = (data: MenuAction): MenuAction => {
    return {
        type: "SELECT_OTHER_MENU",
        id: data.id,
        parentID: data.parentID,
    };
};

export const selectOtherMenuChil = (data: MenuAction): MenuAction => {
    return {
        type: "SELECT_OTHER_MENU_CHIL",
        id: data.id,
    };
};

export const addMenuRole = (data: MenuAction): MenuAction => {
    return {
        type: "LOAD_MENU",
        isSupperAdmin: data.isSupperAdmin,
    };
};

export const restMenu = (): MenuAction => {
    return {
        type: "RESET",
    };
};
