import axios from "../index";
import { PageRequest, BaseService, Pagination, BaseResponse } from "../baseService";


export interface DanhMucQuocTichDTO {
    id: string;
    code: string;
    name: string;
    description: string;
    isActive: boolean;
}

export interface DanhMucQuocTichRequest extends PageRequest {
    isActive?: Boolean;
}
export class DanhMucQuocTichService extends BaseService {

    getDMQuocTich = async (request: DanhMucQuocTichRequest): Promise<Pagination<DanhMucQuocTichDTO[]>> => {
        let result = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: []
        } as Pagination<DanhMucQuocTichDTO[]>;
        await axios.get('/danh-muc-quoc-tich/search', {
            params: request
        }).then(response => {
            if (response.data.error_code === 'SUCCESSFUL' && response.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content
                }
            }
        }).catch();
        return result;
    }

    insertOrUpdate = async (request: DanhMucQuocTichDTO): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios.post('/danh-muc-quoc-tich/insertOrUpdate', JSON.stringify(request), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_message
            }
        }).catch(() => result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' });
        return result;
    }

    deleteById = async (id: string): Promise<BaseResponse> => {
        let result = {} as BaseResponse;
        await axios.post('/danh-muc-quoc-tich/delete', JSON.stringify({ id: id }), this.getTokenRequestHeaders()).then(response => {
            result = {
                isSuccess: response.data.error_code === 'SUCCESSFUL',
                message: response.data.error_message
            }
        }).catch(() => result = { isSuccess: false, message: 'Có lỗi khi thực hiện kết nối tới server' });
        return result;
    }


}
