import { Box, Button, createStyles, Grid, makeStyles, Snackbar, TextField, Theme } from "@material-ui/core";
import React, { Dispatch, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { UserManagementService } from "../../../apis/userMgt/userManagementService";
import { CustomAlert } from "../../../components/commons/alert";
import { bootstrapTextFieldHook } from "../../../components/commons/input/bootstrap";
import { getString } from "../../../helpers/localStorage";
import { hideLoading, LoadingAction, showLoading } from "../../../redux/actions/applicationAction";
import StringUtil from "../../../utils/stringUtils";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        snackBar: {
            top: "70px",
        },
    })
);

type PasswordDTO = {
    oldPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
};
export const ChangePassword = () => {
    const classes = useStyles();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();

    const dispatch = useDispatch<Dispatch<LoadingAction>>();

    const [alertError, setAlertError] = useState(false);
    const [messageNotify, setMessageNotify] = useState("");
    const [alertType, setAlertType] = useState<"success" | "warning" | "info" | "error" | undefined>(undefined);

    const [errors, setErrors] = useState<PasswordDTO>({} as PasswordDTO);
    const [passwordDTO, setPasswordDTO] = useState<PasswordDTO>({} as PasswordDTO);
    const history = useHistory();

    const handleChange = (e: any) => {
        setPasswordDTO((passwordDTO) => ({
            ...passwordDTO,
            [e.target.name]: e.target.value,
        }));
    };

    /* eslint-disable no-useless-escape */
    const validateFromRequired = (): boolean => {
        let errorValidation = {} as PasswordDTO;
        if (StringUtil.isNullOrEmty(passwordDTO.oldPassword)) {
            errorValidation.oldPassword = "Mật khẩu cũ bắt buộc nhập";
        }
        if (StringUtil.isNullOrEmty(passwordDTO.newPassword)) {
            errorValidation.newPassword = "Mật khẩu mới bắt buộc nhập";
        } else if (passwordDTO.newPassword.length < 8) {
            errorValidation.newPassword = "Mật khẩu mới yêu cầu tối thiểu 8 kí tự";
        } else {
            let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
            if (!strongRegex.test(passwordDTO.newPassword)) {
                errorValidation.newPassword = "Mật khẩu yêu cầu bao gồm cả chữ và số, ký tự đặc biệt: *,$,#...";
            }
        }
        if (StringUtil.isNullOrEmty(passwordDTO.newPasswordRepeat)) {
            errorValidation.newPasswordRepeat = "Nhập lại mật khẩu mới bắt buộc nhập";
        } else if (passwordDTO.newPassword.length < 8) {
            errorValidation.newPasswordRepeat = "Nhập lại mật khẩu yêu cầu tối thiểu 8 kí tự";
        }
        setErrors(errorValidation);
        return Object.keys(errorValidation).length === 0;
    };
    const validateNewAndRepeatPassword = (): boolean => {
        let errorValidation = {} as PasswordDTO;
        if (!(StringUtil.isNullOrEmty(passwordDTO.newPassword) && StringUtil.isNullOrEmty(passwordDTO.newPasswordRepeat))) {
            if (passwordDTO.newPassword !== passwordDTO.newPasswordRepeat) {
                errorValidation.newPassword = "Mật khẩu mới và mật khẩu mới nhập lại không khớp nhau";
                errorValidation.newPasswordRepeat = "Mật khẩu mới và mật khẩu mới nhập lại không khớp nhau";
            }
            if (passwordDTO.newPassword === passwordDTO.oldPassword) {
                errorValidation.newPassword = "Mật khẩu mới không được trùng với mật khẩu hiện tại";
            }
        }
        setErrors(errorValidation);
        return Object.keys(errorValidation).length === 0;
    };

    const handleSubmit = () => {
        dispatch(showLoading());
        if (validateFromRequired() && validateNewAndRepeatPassword()) {
            let userId = getString("C_USER");
            let requestBody = {
                userId: userId,
                oldPassword: passwordDTO.oldPassword,
                newPassword: passwordDTO.newPassword,
            };
            new UserManagementService().changePassword(requestBody).then((p) => {
                setMessageNotify(p.error_message);
                setAlertError(true);
                setAlertType("SUCCESSFUL" === p.error_code ? "success" : "error");
            });
        }
        dispatch(hideLoading());
    };

    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={2} direction="row">
                <Grid item xs={12}>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Mật khẩu hiện tại"}
                            required
                            name="oldPassword"
                            value={passwordDTO.oldPassword}
                            onChange={handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            error={Boolean(errors.oldPassword)}
                            InputProps={{
                                disableUnderline: true,
                                classes: inputStyles,
                            }}
                            FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                            helperText={Boolean(errors.oldPassword) && errors.oldPassword}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            type="password"
                            label={"Mật khẩu mới"}
                            required
                            name="newPassword"
                            value={passwordDTO.newPassword}
                            onChange={handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            error={Boolean(errors.newPassword)}
                            InputProps={{
                                disableUnderline: true,
                                classes: inputStyles,
                            }}
                            FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                            helperText={Boolean(errors.newPassword) && errors.newPassword}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            type="password"
                            label={"Nhập lại mật khẩu mới"}
                            required
                            name="newPasswordRepeat"
                            value={passwordDTO.newPasswordRepeat}
                            onChange={handleChange}
                            InputLabelProps={{ classes: labelStyles }}
                            error={Boolean(errors.newPasswordRepeat)}
                            InputProps={{
                                disableUnderline: true,
                                classes: inputStyles,
                            }}
                            FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                            helperText={Boolean(errors.newPasswordRepeat) && errors.newPasswordRepeat}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Box display="flex" paddingLeft="10px" flexDirection="column" fontStyle="italic" fontSize="13px">
                        <Box>Mật khẩu phải có ít nhất 8 ký tự.Không trùng với mật khẩu đang sử dụng!</Box>
                        <Box>Lựa chọn mật khẩu an toàn bao gồm cả chữ và số, nên có thêm các ký tự đặc biệt như *,$,#...để độ an toàn cao nhất</Box>
                    </Box>
                </Grid>
                <Grid item xs={3} className={classes.root}>
                    <Button variant="contained" color="primary" disableElevation onClick={() => handleSubmit()}>
                        Lưu
                    </Button>
                    <Button variant="contained" color="default" disableElevation onClick={() => history.go(-1)}>
                        Trở về
                    </Button>
                </Grid>
            </Grid>
            <Snackbar className={classes.snackBar} open={alertError} onClose={() => setAlertError(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <CustomAlert onClose={() => setAlertError(false)} severity={alertType}>
                    {messageNotify}
                </CustomAlert>
            </Snackbar>
        </form>
    );
};
