export const OptionYears = () => {
    const year = new Date().getFullYear();
    const years = Array.from(new Array(5), (val, index) => year - index);
    return (
        <>
            {years.map((year, index) => {
                return (
                    <option key={`year${index}`} value={year}>
                        {year}
                    </option>
                );
            })}
        </>
    );
};

export const OptionMonthsInYear = () => {
    const months = Array.from(new Array(12), (val, index) => index + 1);
    return (
        <>
            {months.map((month, index) => {
                return (
                    <option key={`mont${index}`} value={month - 1}>
                        {month}
                    </option>
                );
            })}
        </>
    );
};