import { Box, Button, createStyles, Grid, IconButton, InputBase, InputLabel, makeStyles, NativeSelect, Snackbar, Theme } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { downLoadFile } from "../../apis/Common/exportUtil";
import { ImportDauKyService } from "../../apis/importDauKy/importDauKyService";
import { AlertMessage, CustomAlert } from "../../components/commons/alert";
import { AttachIcon } from "../../components/commons/attachFileTooltip/attachFileTooltip";
import { bootstrapTextFieldHook } from "../../components/commons/input/bootstrap";
import { OverlayLoading } from "../../components/overlayLoading/overlayLoading";
import { hideLoading, LoadingAction, showLoading } from "../../redux/actions/applicationAction";
import { ImportDauKyCuaRequest } from "./model/ImportDauKyModel";
import { LoaiDoiTuong } from "./thongTinChuyenNganh";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        nameFile: {
            textDecoration: "none",
            color: blue[500],
            marginRight: "auto",
        },
        button: {
            margin: "0px 5px",
        },
        container: {
            marginTop: "5px",
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            borderWidth: "2px",
            borderRadius: "2px",
            borderStyle: "dashed",
            backgroundColor: "#fafafa",
            color: "#bdbdbd",
            outline: "none",
        },
        buttonFile: {
            justifyContent: "flex-start",
            color: blue[500],
        },
    }),
);
export const ImportDauKyComponent = () => {
    const classes = useStyles();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const dispatch = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<ImportDauKyCuaRequest>({ loaiGiayPhep: LoaiDoiTuong[0].ma, tenLoaiGiayPhep: LoaiDoiTuong[0].ten });
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };
    const [file, setFile] = useState<any>();
    const service = new ImportDauKyService();
    useEffect(() => { }, []);

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };

    function handleDownloadTemplateFile() {
        service.downloadFileTemplate(data).then((response) => {
            downLoadFile(response.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', data.tenLoaiGiayPhep + ".xlsx");
        }).catch(() => {
            showMessageAlert("Có lỗi khi thực hiện tải file template", false);
        });
    }
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>IMPORT ĐẦU KỲ</span>
            </div>
            <Grid container spacing={2} direction="row" alignItems="center">
                <OverlayLoading />
                <Grid item xs={6} className="gridItem">
                    <InputLabel classes={labelStyles}>Loại đối tượng</InputLabel>
                    <NativeSelect
                        input={<InputBase fullWidth classes={inputStyles} />}
                        value={data.loaiGiayPhep}
                        onChange={(e) => setData({ ...data, loaiGiayPhep: e.target.value, tenLoaiGiayPhep: e.target.selectedOptions[0].text })}
                    >
                        {LoaiDoiTuong.map((item, index) => {
                            return (
                                <option key={`mont${index}`} value={item.ma}>
                                    {item.ten}
                                </option>
                            );
                        })}
                    </NativeSelect>
                </Grid>
                <Grid item xs={1} className="gridItem">
                    <input
                        id={`contained-button-file`}
                        type="file"
                        hidden
                        onChange={async (e) => {
                            if (e.target.files !== null) {
                                let file = e.target.files[0];
                                if (file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                                    showMessageAlert("Vui lòng chọn tệp tin có định dạng [.xlsx]", false);
                                    return;
                                }
                                setFile(file);
                                e.target.value = '';
                            }
                        }}
                    />
                    <label htmlFor={`contained-button-file`}>
                        <Button variant="contained" style={{
                            marginTop: "35px"
                        }} color="primary" component="span" size="small" disableElevation>
                            Chọn
                        </Button>
                    </label>
                </Grid>
                <Grid item xs={4} className="gridItem">
                    {file && (
                        <Box display="flex">
                            <Box display="flex" flexDirection="column">
                                <Button
                                    size="small"
                                    className={classes.buttonFile}
                                    disableRipple
                                    startIcon={<AttachIcon fileName={file.name} />}
                                    style={{
                                        display: "flex",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        width: "300px",
                                        whiteSpace: "nowrap",
                                        marginTop: "30px"
                                    }}
                                >
                                    {file.name.length > 30 ? file.name.slice(0, 33) + "..." + file.name.slice(file.name.length - 10) : file.name}
                                </Button>
                            </Box>

                            <IconButton style={{
                                marginTop: "30px"
                            }} onClick={() => {
                                setFile(null);
                            }} size="small" disableRipple color="secondary" title="Xóa">
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <Button variant="contained" style={{ marginRight: "10px" }} color="primary" component="span" size="small" disableElevation onClick={(e: any) => handleDownloadTemplateFile()}>
                        Tải mẫu
                    </Button>
                    <Button variant="contained" color="primary" component="span" size="small" disableElevation onClick={(e: any) => {
                        if (file && file != undefined && file != null) {
                            dispatch(showLoading());
                            service.importData(data, file).then((response) => {
                                if (response.data) {
                                    console.log("My respone", response);
                                    if (response.data.error_code == "SUCCESSFUL") {
                                        showMessageAlert('Yêu cầu thực hiện thành công', true);
                                    } else {
                                        showMessageAlert(response.data.error_message, false);
                                    }
                                } else {
                                    showMessageAlert("Có lỗi khi thực hiện yêu cầu", false);
                                }
                                console.log('response.data : ', response.data)
                            }).catch(() => {
                                showMessageAlert("Có lỗi khi thực hiện yêu cầu", false);
                            }).finally(() => {
                                dispatch(hideLoading());
                            });
                        }else{
                            showMessageAlert("Vui lòng đính kèm tệp tin", false);
                        }
                    }}>
                        Import
                    </Button>
                </Grid>
                <Grid item xs={2} className="button-group-center">

                </Grid>


            </Grid>
            {/* <ConfirmationDialogRaw
                id="confirm-dialog-dong-bo"
                keepMounted
                open={openConfirm}
                title="Đồng bộ"
                content="Bạn có đồng ý thực hiện thao tác này?"
                onClose={handleDongBo}
            /> */}
            <Snackbar
                open={alertMessage.open}
                onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={3000}
            >
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                    {alertMessage.content}
                </CustomAlert>
            </Snackbar>
        </div >
    );
};

