import { LoaiHinhKhenThuong } from "../../model/chuyenNganh/khenThuongDTO";
import { TraCuuCaNhanToChucDTO } from "../../model/traCuuCaNhanToChuc/traCuuCaNhanToChuc";
import { BaseService, PageRequest, Pagination } from "../baseService";
import api from "../index";
import { TraCuuCaNhanRequest } from "./hoSoKhenThuongService";
export interface BaoCaoDanhSachKhenThuongRequest extends PageRequest {
    year?: number;
    fromDate?: String;
    toDate?: String;
    loaiHinhKhenThuong?: LoaiHinhKhenThuong;
    hinhThucKhenThuongId?: number;
    danhHieuThiDuaId?: number;
    downloadType?: "MSWORD" | "EXCEL" | "PDF";
}
export interface ThongKeResponseCommonsDTO {
    label: string;
    totalKT: number;
    totalHuyKT: number;

    maSo: string;
    donViTinh: string;
    total: number;
    totalBangKhen: number;
    totalChienSiThiDua: number;
    totalCoThiDua: number;
    totalHuyHieu: number;

    maLoaiHinhKhenThuong: string;
}
export interface ThongKeKhenThuongTheoDonViDTO {
    tenDonVi: string;
    chiTiets: ThongKeResponseCommonsDTO[];
    totalKT: number;
    totalHuyKT: number;
}


export class BaoCaoThongKeKhenThuongService extends BaseService {
    getDataBaoCao = async (request: BaoCaoDanhSachKhenThuongRequest): Promise<Pagination<TraCuuCaNhanToChucDTO[]>> => {
        let result = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: [],
        } as Pagination<TraCuuCaNhanToChucDTO[]>;
        await api
            .get("/bao-cao-thong-ke/bao-cao", {
                params: request,
            })
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = {
                        ...result,
                        total: response.data.data.total,
                        rows: response.data.data.content,
                    };
                }
            })
            .catch();
        return result;
    };

    getThongKe = async (type: string, year?: String, fromDate?: string, toDate?: string, month?: String): Promise<ThongKeResponseCommonsDTO[]> => {
        let request = {
            year: year,
            month: month,
            fromDate: fromDate,
            toDate: toDate,
        };
        let result: ThongKeResponseCommonsDTO[] = [];
        await api
            .get("/bao-cao-thong-ke/thong-ke/" + type, {
                params: request,
            })
            .then((resp) => {
                if (resp.data.error_code === "SUCCESSFUL") {
                    result = resp.data.data;
                }
            })
            .catch();
        return result;
    };
    exportFile = async (request: BaoCaoDanhSachKhenThuongRequest): Promise<any> => {
        return api.get("/bao-cao-thong-ke/bao-cao/export", { params: request, responseType: "blob", timeout: 30000 });
    };
    // exportFileBC = async (request: any, type:): Promise<any> => {
    //     return api.get(`/bao-cao-thong-ke/thong-ke/${type}/export`, { params: request, responseType: "blob", timeout: 30000 });
    // };
    exportFileBC = async (type: string, year?: String, fromDate?: string, toDate?: string, downloadType?: "MSWORD" | "EXCEL" | "PDF", month?: String): Promise<any> => {
        let request = {
            year: year,
            month: month,
            fromDate: fromDate,
            toDate: toDate,
            downloadType,
        };
        return api.get(`/bao-cao-thong-ke/thong-ke/${type}/export`, { params: request, responseType: "blob", timeout: 30000 });
    };

    thongKeTheoDonViKhenThuong = async (modeTK: string, year?: number, tuNgay?: number, denNgay?: number): Promise<ThongKeKhenThuongTheoDonViDTO[]> => {
        let request = {
            type: modeTK,
            year: year,
            fromDate: tuNgay,
            toDate: denNgay
        };
        let result: ThongKeKhenThuongTheoDonViDTO[] = [];
        await api
            .post("/bao-cao-thong-ke/thong-ke-theo-don-vi-khen-thuong", request)
            .then((resp) => {
                if (resp.data.error_code === "SUCCESSFUL") {
                    result = resp.data.data;
                }
            })
            .catch();
        return result;
    };
    exportThongKeTheoDonViKhenThuong = async (modeTK: string, year?: number, tuNgay?: number, denNgay?: number, downloadType?: "MSWORD" | "EXCEL" | "PDF") => {
        let request = {
            type: modeTK,
            year: year,
            fromDate: tuNgay,
            toDate: denNgay,
            downloadType: downloadType
        };
        let result: ThongKeKhenThuongTheoDonViDTO[] = [];
        return api.post("/bao-cao-thong-ke/thong-ke-theo-don-vi-khen-thuong/export", request, { responseType: "blob" });
    };

    chiTietThongKe = async (params: PageRequest, index: number, field: string, typeMode : Number, _year?: String, _fromDate?: string, _toDate?: string, _month?: String): Promise<Pagination<TraCuuCaNhanToChucDTO[]>> => {
        let result = {
            page: params.pageNumber,
            size: params.pageSize,
            total: 0,
            rows: [],
        } as Pagination<TraCuuCaNhanToChucDTO[]>;
        let request = {
            pageNumber: params.pageNumber,
            pageSize: params.pageSize,
            index: index,
            field: field,
            typeMode: typeMode,
            year: _year,
            month: _month,
            fromDate: _fromDate,
            toDate: _toDate
        }
        await api
            .get("/ho-so-khen-thuong/chi-tiet-thong-ke", {
                params: request,
                
            })
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = {
                        ...result,
                        total: response.data.data.total,
                        rows: response.data.data.content,
                    };
                }
            })
            .catch();
        return result;
    };
}
